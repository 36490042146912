import React, { useEffect, useState } from "react";
import axios from "axios";

import "./AddOpportunitiesModal.css";

function AddOpportunitiesModal({ id }) {
  const [countries, setCountries] = useState([]);
  const [governates, setGovernates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [skills, setSkills] = useState([]);
  const [disabilities, setDisabilities] = useState([]);
  const [impacts, setImpacts] = useState([]);
  const [selectedImpacts, setSelectedImpacts] = useState([]);
  const [selectedDisabilities, setSelectedDisabilities] = useState([]);
  const [formData, setFormData] = useState({
    status: "Ongoing",
    title: "",
    type: "Skill-Based",
    is_group: false,
    ages_id: "Under 18",
    genders_id: "Male",
    country_id: "",
    governate_id: "",
    district_id: "",
    occupation: "Unoccupied",
    frequency: "One Time Only",
    nb_of_spots: "",
    transporation: "Not Required",
    online: "No",
    food: "Not Required",
    hours: "",
    start_date: "",
    end_date: "",
    languages: "",
    deadline_date: "",
    final_comments: "",
    experience_required: "",
    tasks_required: "",
    skills: "",
    wheelchair_accessible: true,
    cost: "",
    lives_touched: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [countriesRes, skillsRes, impactsRes, disabilitiesRes] =
          await Promise.all([
            axios.get(process.env.REACT_APP_API + "/countries"),
            axios.get(process.env.REACT_APP_API + "/skills"),
            axios.get(process.env.REACT_APP_API + "/impacts/getAll"),
            axios.get(process.env.REACT_APP_API + "/disabilities"),
          ]);

        setCountries(countriesRes?.data?.data);
        setSkills(skillsRes?.data?.data);
        setImpacts(impactsRes?.data?.Impacts);
        setDisabilities(disabilitiesRes?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (formData.country_id) {
      const fetchGovernates = async () => {
        try {
          const response = await axios.get(
            process.env.REACT_APP_API +
              "/governates/getGovernateByCountry/" +
              formData.country_id
          );
          setGovernates(response.data.data);
        } catch (error) {
          console.error("Error fetching governates:", error);
        }
      };

      fetchGovernates();
    } else {
      setGovernates([]);
    }
  }, [formData.country_id]);

  useEffect(() => {
    if (formData.governate_id) {
      const fetchDistricts = async () => {
        try {
          const response = await axios.get(
            process.env.REACT_APP_API +
              "/districts/getDistrictByGovernate/" +
              formData.governate_id
          );
          setDistricts(response.data.data);
        } catch (error) {
          console.error("Error fetching districts:", error);
        }
      };

      fetchDistricts();
    } else {
      setDistricts([]);
    }
  }, [formData.governate_id]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const oppResponse = await axios.post(
        process.env.REACT_APP_API + `/opportunities/` + id,
        {
          ...formData,
        }
      );

      await axios.post(
        process.env.REACT_APP_API + `/ImpactOpportunity/create`,
        {
          opportunity_id: oppResponse?.data?.data?.id,
          impact_id: selectedImpacts,
        }
      );
      await axios.post(
        process.env.REACT_APP_API + `/opportunity_disabilities`,
        {
          opportunity_id: oppResponse?.data?.data?.id,
          disability_id: selectedDisabilities,
        }
      );

      alert("Opportunity created successfully!");
    } catch (err) {
      setError("Failed to create opportunity");
    } finally {
      setLoading(false);
      setFormData({
        status: "Ongoing",
        title: "",
        type: "Skill-Based",
        is_group: false,
        ages_id: "Under 18",
        genders_id: "Male",
        country_id: "",
        governate_id: "",
        district_id: "",
        occupation: "Unoccupied",
        frequency: "One Time Only",
        nb_of_spots: "",
        transporation: "Not Required",
        online: "No",
        food: "Not Required",
        hours: "",
        start_date: "",
        end_date: "",
        languages: "",
        deadline_date: "",
        final_comments: "",
        experience_required: "",
        tasks_required: "",
        skills: "",
        wheelchair_accessible: true,
        cost: "",
        lives_touched: "",
      });
      setSelectedDisabilities([]);
      setSelectedImpacts([]);
      setError(null);
    }
  };

  return (
    <div className="AddOpportunitiesModalContainer">
      <div className="modal fade" id="add-opportunities-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Create Volunteer Opportunity</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              {error && <p className="text-danger">{error}</p>}
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Title*</label>
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    value={formData.title}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Type of opportunity*</label>
                  <select
                    name="type"
                    className="form-control"
                    value={formData.type}
                    onChange={handleChange}
                    required
                  >
                    <option value="Skill-Based">Skill-Based</option>
                    <option value="Time-Based">Time-based</option>
                  </select>
                </div>

                <div className="form-group">
                  <label>Is this a group opportunity?*</label>
                  <select
                    name="is_group"
                    className="form-control"
                    value={formData.is_group}
                    onChange={handleChange}
                    required
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                </div>

                <div className="form-group">
                  <label>Age*</label>
                  <select
                    name="ages_id"
                    className="form-control-textarea"
                    multiple
                    onChange={(e) => {
                      const selectedValues = Array.from(
                        e.target.selectedOptions,
                        (option) => option.value
                      );
                      setFormData((prevData) => ({
                        ...prevData,
                        ages_id: selectedValues,
                      }));
                    }}
                    required
                  >                       
                    <option value="Under 18">Under 18</option>
                    <option value="18-25">18-25</option>
                    <option value="25-35">25-35</option>
                    <option value="Above 35 years old">
                      Above 35 years old
                    </option>
                    <option value="All">All</option>
                  </select>
                </div>

                <div className="form-group">
                  <label>Gender*</label>
                  <select
                    name="genders_id"
                    className="form-control-textarea"
                    multiple
                    onChange={(e) => {
                      const selectedValues = Array.from(
                        e.target.selectedOptions,
                        (option) => option.value
                      );
                      setFormData((prevData) => ({
                        ...prevData,
                        genders_id: selectedValues,
                      }));
                    }}
                    required
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Non-Binary">Non-Binary</option>
                  </select>
                </div>

                <div className="form-group">
                  <label>Country*</label>
                  <select
                    name="country_id"
                    className="form-control"
                    value={formData.country_id}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select a country</option>
                    {countries.map((country) => (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label>Governate*</label>
                  <select
                    name="governate_id"
                    className="form-control"
                    value={formData.governate_id}
                    onChange={handleChange}
                    required
                    disabled={!formData.country_id}
                  >
                    <option value="">Select a governate</option>
                    {governates.map((governate) => (
                      <option key={governate.id} value={governate.id}>
                        {governate.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label>District*</label>
                  <select
                    name="district_id"
                    className="form-control"
                    value={formData.district_id}
                    onChange={handleChange}
                    required
                    disabled={!formData.governate_id}
                  >
                    <option value="">Select a district</option>
                    {districts.map((district) => (
                      <option key={district.id} value={district.id}>
                        {district.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label>Occupation*</label>
                  <select
                    name="occupation"
                    className="form-control"
                    value={formData.occupation}
                    onChange={handleChange}
                    required
                  >
                    <option value="Unoccupied">Unoccupied</option>
                    <option value="School Student">School Student</option>
                    <option value="University Student">
                      University Student
                    </option>
                    <option value="Employee">Employee</option>
                    <option value="Self Employed">Self Employed</option>
                    <option value="Shifting careers">Shifting careers</option>
                    <option value="Employee with job security (contract & benefits)">Employee with job security (contract & benefits)</option>
                    <option value="Employee without contract & benefits">Employee without contract & benefits</option>
                  </select>
                </div>

                <div className="form-group">
                  <label>Frequency*</label>
                  <select
                    name="frequency"
                    className="form-control"
                    value={formData.frequency}
                    onChange={handleChange}
                    required
                  >
                    <option value="Any">Any</option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Bi-Weekly">Bi-Weekly</option>
                    <option value="Bi-Monthly">Bi-Monthly</option>
                    <option value="One Time Only">One Time Only</option>
                  </select>
                </div>

                <div className="form-group">
                  <label>How many volunteers do you need for this role?*</label>
                  <input
                    type="number"
                    name="nb_of_spots"
                    className="form-control"
                    value={formData.nb_of_spots}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Transport*</label>
                  <select
                    name="transport"
                    className="form-control"
                    value={formData.transport}
                    onChange={handleChange}
                    required
                  >
                    <option value="Not Required">Not Required</option>
                    <option value="Provided">Provided</option>
                    <option value="Required">Required</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Online*</label>
                  <select
                    name="online"
                    className="form-control"
                    value={formData.online}
                    onChange={handleChange}
                    required
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Food*</label>
                  <select
                    name="food"
                    className="form-control"
                    value={formData.food}
                    onChange={handleChange}
                    required
                  >
                    <option value="Not Required">Not Required</option>
                    <option value="Provided">Provided</option>
                    <option value="Required">Required</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Hours*</label>
                  <input
                    type="number"
                    name="hours"
                    className="form-control"
                    value={formData.hours}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Start Date*</label>
                  <input
                    type="date"
                    name="start_date"
                    className="form-control"
                    value={formData.start_date}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>End Date*</label>
                  <input
                    type="date"
                    name="end_date"
                    className="form-control"
                    value={formData.end_date}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Languages*</label>
                  <input
                    type="text"
                    name="languages"
                    className="form-control"
                    value={formData.languages}
                    onChange={handleChange}
                  />
                </div>
                
                <div className="form-group">
                  <label>Deadline Date*</label>
                  <input
                    type="date"
                    name="deadline_date"
                    className="form-control"
                    value={formData.deadline_date}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>What experience is needed for this role?*</label>
                  <textarea
                    name="experience_required"
                    className="form-control-textarea"
                    value={formData.experience_required}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>

                <div className="form-group">
                  <label>Please describe this role.*</label>
                  <textarea
                    name="final_comments"
                    className="form-control-textarea"
                    value={formData.final_comments}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>

                <div className="form-group">
                  <label>Task*</label>
                  <input
                    type="text"
                    name="tasks_required"
                    className="form-control"
                    value={formData.tasks_required}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Skills*</label>
                  <select
                    name="skills"
                    className="form-control-textarea"
                    multiple
                    onChange={(e) => {
                      const selectedValues = Array.from(
                        e.target.selectedOptions,
                        (option) => `#${option.value}`
                      ).join("");
                      setFormData({
                        ...formData,
                        skills: selectedValues,
                      });
                    }}
                    required
                  >
                    {skills
                      .filter((skill) =>
                        skill.type.toLowerCase().includes("skills")
                      )
                      .map((skill) => (
                        <option key={skill.id} value={skill.id}>
                          {skill.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="form-group">
                  <label>Expertise*</label>
                  <select
                    name="expertise"
                    className="form-control-textarea"
                    multiple
                    onChange={(e) => {
                      const selectedValues = Array.from(
                        e.target.selectedOptions,
                        (option) => `#${option.value}`
                      ).join("");

                      setFormData((prevData) => ({
                        ...prevData,
                        skills: prevData.skills + selectedValues,
                      }));
                    }}
                    required
                  >
                    {skills
                      .filter((skill) => skill.type === "E")
                      .map((skill) => (
                        <option key={skill.id} value={skill.id}>
                          {skill.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="form-group">
                  <label>Impacts*</label>
                  <select
                    name="impacts"
                    className="form-control-textarea"
                    multiple
                    onChange={(e) => {
                      const selectedValues = Array.from(
                        e.target.selectedOptions,
                        (option) => option.value
                      );
                      setSelectedImpacts(selectedValues);
                    }}
                    required
                  >
                    {impacts.map((impact) => (
                      <option key={impact.id} value={impact.id}>
                        {impact.title}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label>Disabilities*</label>
                  <select
                    name="disabilities"
                    className="form-control-textarea"
                    multiple
                    onChange={(e) => {
                      const selectedValues = Array.from(
                        e.target.selectedOptions,
                        (option) => option.value
                      );
                      setSelectedDisabilities(selectedValues);
                    }}
                    required
                  >
                    {disabilities.map((disability) => (
                      <option key={disability.id} value={disability.id}>
                        {disability.text}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label>Wheelchair accessible*</label>
                  <select
                    name="wheelchair_accessible"
                    className="form-control"
                    value={formData.wheelchair_accessible}
                    onChange={handleChange}
                    required
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                </div>

                <div className="form-group">
                  <label>Cost*</label>
                  <input
                    type="number"
                    name="cost"
                    className="form-control"
                    value={formData.cost}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Lives touched*</label>
                  <input
                    type="number"
                    name="lives_touched"
                    className="form-control"
                    value={formData.lives_touched}
                    onChange={handleChange}
                    required
                  />
                </div>

                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Create Opportunity"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOpportunitiesModal;
